/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var lang = $('body').hasClass('lang-fr') ? 'fr' : 'en';

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Add 'js' class on HTML tag
        $('html').addClass('js');

        // Add element so we can change its layout in CSS and have a better way
        // to trigger narrow resolution (or any other where there's no other way)
        $('body').append('<div id="responsiveResTrig"></div>');

        $(window).on('resize', function(){
          UTIL.fire('common', 'setResponsiveClasses');
        });

        // Fires Fancybox
        UTIL.fire('common', '_fancybox');

        // Home FPO slider
        UTIL.fire('common', 'homeSlider');

        // Pop up form
        UTIL.fire('common', 'popupForm');

        // Multiple location form select
        UTIL.fire('common', 'multipleForm');

        // Add responsive version class on body
        UTIL.fire('common', 'setResponsiveClasses');

        // Collapsible elements
        UTIL.fire('common', 'collapse');

        // Collapsed text
        UTIL.fire('common', 'readMore');

        // Add focus to input when add-on is clicked
        UTIL.fire('common', 'inputFocus');

        // Header location dropdown
        UTIL.fire('common', 'headerLocations');

        //Add map overlay to block zooming while scrolling
        UTIL.fire('common', 'mapOverlay');

        // MatchHeight functions
        UTIL.fire('common', '_matchHeight');

        // Create footer map for single location (maps.js)
        UTIL.fire('common', '_mapSingle');

        // Create footer map for multiple locations (maps.js)
        UTIL.fire('common', '_mapMultiple');

        // ContactForm7 DomEvents
        UTIL.fire('common', '_cf7_events');
      },

      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },

      _fancybox: function() {
        $('.fancybox').fancybox();
      },

      // ContactForm7 DomEvents
      // Replace deprecated cf7 function on_sent_ok(), on_submit() or other events
      // https://contactform7.com/dom-events/
      _cf7_events: function() {
        // return;
        // wpcf7mailsent: Fires when an Ajax form submission has completed successfully, and mail has been sent.
        document.addEventListener( 'wpcf7mailsent', function( event ) {
          formConfirmation(event.detail.contactFormId);
        }, false );

        // wpcf7submit: Fires when an Ajax form submission has completed successfully, regardless of other incidents.
        document.addEventListener( 'wpcf7submit', function( event ) {
          formScroll(event.detail.contactFormId);
        }, false );
      },

      homeSlider: function(){
        if($('#slider .carousel').length) {
          // Slider mode
          var mode;
          if($('#slider .mode-fade').length) {
            mode = 'fade';
          } else {
            mode = 'slide';
          }



          // Slide titles option
          var pauseTime = 8000;
          var timeoutPlay;
          var slideCount = $('#slider .carousel li').length;
          $('#slider .carousel-title ul li').first().addClass('current');

          var sliderElem = $('#slider .carousel').lightSlider({
            item: 1,
            slideMove: 1,
            slideMargin: 0,
            mode: mode,
            auto: true,
            loop: true,
            speed: 1000,
            pause: 8000,
            adaptiveHeight: false,
            autoWidth:false,
            controls: false,
            pager: false,

            responsive : [
             {
               breakpoint: 991,
               settings: {
                 adaptiveHeight: true
               }
             }
            ],

            onBeforeSlide: function(el){
              $('#slider .carousel-title ul li').removeClass('current');
              $($('#slider .carousel-title ul li')[sliderElem.getCurrentSlideCount()-1]).addClass('current');
            }
          });

          // For slide titles option
          $('#slider .carousel-title ul li a').click(function(){
            sliderElem.pause();
            sliderElem.goToSlide($(this).parent().index()+1);
            $('#slider .carousel-title ul li').removeClass('current');
            $(this).parent().addClass('current');

            clearTimeout(timeoutPlay);
            timeoutPlay = setTimeout(function(){
              sliderElem.play();
            }, pauseTime);

            $(this).blur();

            return false;
          });
        }
      },

      popupForm: function() {
        if($('.popupForm').length){
          var closeBtnLabel = "Close";
          if($('.lang-fr')){
            closeBtnLabel = "Fermer";
          }

          $('.popupForm').fancybox({
            maxWidth: 800,
            padding: 0,
            tpl: {
              closeBtn: '<a title="'+closeBtnLabel+'" class="fancybox-item fancybox-close" href="javascript:;"></a>',
            },
            afterClose: function() {
              // Show form
              $('form .fieldsets').show();

              // Hide confirmation message
              $('form .conf-msg').hide();
            }
          });
        }
      },

      multipleForm: function() {
        if($('form.form-multiple').length){
          $('#featPopup .recipient-list li').each(function(){
            var label = $(this).find('.label').text();
            var value = $(this).find('.value').text();

            $('form #recipient').append($('<option>', {
              value: value,
              text: label
            }));
          });
        }
      },

      collapse: function() {
        $('.collapse-btn').on('click', function(e) {
          e.preventDefault();
          var $this = $(this);
          var $collapse = $this.closest('.collapse-group').find('.collapse');
          $collapse.collapse('toggle');
        });
      },

      setResponsiveClasses: function(){
        // Add or remove classes on window resize
        var availableSizes = ['mobile','tablet','narrow','wide'];

        keepSelectedSizeOnly = function(size){
          for (a = 0; a < availableSizes.length; ++a) {
            if (availableSizes[a] != size){ $('body').removeClass(availableSizes[a]);}
          }
          $('body').addClass(size);
        };

        setSize = function(size){
          // If the site doesn't already have the correct class
          if((!$('body.'+size).length)){

            if(size == 'mobile'){
              keepSelectedSizeOnly('mobile');
            }else{
              keepSelectedSizeOnly(size);
            }
          }
        };

        // is mobile
        if($('#responsiveResTrig').css('width') == '50px') {
          setSize('mobile');
        }
        // is tablet resolution
        else if($('#responsiveResTrig').css('width') == '75px') {
          setSize('tablet');
        }
        // is wide resolution
        else if($('#responsiveResTrig').css('width') == '200px') {
          setSize('wide');
        }
        // is narrow resolution
        else {
          setSize('narrow');
        }
      },

      headerLocations: function(){
        // Header location dropdown

        animateMenu = function(action) {
          if(action == 'open') {
            $('#siteHeader .infos').addClass('on');
            $('#siteHeader .infos > .content ul').stop().animate({
              'height': headerLocHeightOpened
            }, 250);
          }
          else if(action == 'close') {
            $('#siteHeader .infos').removeClass('on');
            $('#siteHeader .infos > .content ul').stop().animate({
              'height': headerLocHeightClosed
            }, 250);
          }
        };

        if($('.has-multi-locs-header').length){
          // ----------------------------- Narrow + -----------------------------
          var $headerLocTrigger = $('#siteHeader .infos > .content ul li:first-child .trig');
          var headerLocCount = $('#siteHeader .infos > .content ul li').length;
          var headerLocHeightClosed = $('#siteHeader .infos > .content ul').height();
          var headerLocHeightOpened = (headerLocCount * headerLocHeightClosed) + (headerLocCount - 1); //(nb of items * item height) + sep lines

          // Other locations
          $headerLocTrigger.click(function(){
            if(!$('body.mobile, body.tablet').length){
              if($('#siteHeader .infos').hasClass('on')) {
                animateMenu('close');
              }
              else {
                animateMenu('open');
              }
            }

            $(this).blur();
          });

          // Close dropdown if the user clicks outside that element (eg. touch screen)
          $(document).on('click', function(event) {
            if(!$(event.target).closest($('#siteHeader .infos > .content a, #siteHeader .infos > .content .trig'), $('#siteHeader .infos > .content')).length) {
              animateMenu('close');
            }
          });

          // Close dealers dropdown when window is resized
          $(window).on('resize', function(){
            // Make sure to get the correct height for dropdown on desktop
            if(!$('body.mobile, body.tablet').length){
              headerLocHeightClosed = $('#siteHeader .infos > .content').show().find('ul li:first-child').height();
              headerLocHeightOpened = (headerLocCount * headerLocHeightClosed) + (headerLocCount - 1); //(nb of items * item height) + sep lines
            }

            // Close
            $('#siteHeader .infos').removeClass('on');
            $('#siteHeader .infos > .content ul').css({
              height: headerLocHeightClosed
            });
          });



          // ----------------------------- Mobile, Tablet -----------------------------
          // Open/Close dropdowns
          $('#siteHeader .infos .mobile > ul > li').has('> .content').find('.btn > a').click(function() {
            var $clickedLink = $(this);
            var $otherItems = $('#siteHeader .infos .mobile > ul > li .btn > a').not($clickedLink).parents('#siteHeader .infos .mobile > ul > li');

            $otherItems.find('> .content').hide();
            $otherItems.removeClass('on');

            if($clickedLink.parent().siblings('.content')) {
              $clickedLink.parent().siblings('.content').toggle();
              $clickedLink.parents('#siteHeader .infos .mobile > ul > li').toggleClass('on');

              $clickedLink.blur();

              return false;
            }
          });
        }
      },

      mapOverlay: function(options){
        //Add map overlay to block zooming while scrolling
        if($('.map').length){

          // Add the div dynamically
          $('.map').append('<div class="overlay"></div>');

          // Deactivate the overlay on click of the element
          $('.map').find('.overlay').click(function(){
            $(this).hide();
          });

          // Reactivate the overlay on mouseout of the element ...
          $('.map').find('.map-content').mouseout(function(){
            $('.map ').find('.overlay').show();
          });

          // ... or if the user clicks outside that element (eg. touch screen)
          $(document).on('click', function(event) {
            if(!$(event.target).closest('.map').length) {
              $('.map').find('.overlay').show();
            }
          });
        }
      },

      readMore: function(){
        // Collapsed text
        toggleText = function(className){
          var termExpand = (lang == 'fr') ? "Lire la suite" : "Read More";
          var termCollapse = (lang == 'fr') ? "Réduire" : "Collapse Text";

          var symbolExpand = '+';
          var symbolCollapse = '-';

          $(className).each(function(){
            // Add Bootstrap class
            $(this).addClass('collapse');

            if(!$(this).find('+ .readmore').length){
              $(this).after('<p class="readmore"><a href="#"><span>' + symbolExpand + ' </span>'+termExpand+'</a></p>');
            }
          });

          $(className +' + .readmore a').click(function(){
            $trig = $(this);
            $textMore = $(this).parent().prev();

            if($textMore.length){
              $textMore.collapse('toggle');

              // Change trigger text when text is expanded
              $textMore.on('shown.bs.collapse', function(){
                $trig.html('<span>' + symbolCollapse + ' </span>' + termCollapse);
              });
              // Change trigger text when text is collapsed
              $textMore.on('hidden.bs.collapse', function(){
                $trig.html('<span>' + symbolExpand + ' </span>' + termExpand);
              });

              $trig.blur();

              return false;
            }
          });
        };

        // Set all read more texts
        toggleText('.textMore:not(.mobile)');

        // Set all read more texts specific to mobile version
        if($('body.mobile').length) {
          toggleText('.textMore.mobile');
        }
      },

      inputFocus: function() {
        // Add focus to input when add-on is clicked
        if ($('form .input-group-addon')) {
          $('.input-group-addon').click(function() {
            $(this).parent().find('input').focus();
          });
        }
      },

      _matchHeight: function() {
        // Feature cards
        if($('#features .listWImgs .item .wrap').length){
          $('#features .listWImgs .item .wrap').matchHeight();
        }

        // Feature logoWarranty
        if($('#features .logoWarranty').length){
          $('#features .logoWarranty .img').matchHeight();
        }

        // Slider titles (text)
        if($('#slider .carousel-title').length){
          $('#slider .carousel-title li a').matchHeight();
        }
      },

      _mapSingle: function() {
        // Create footer map for single location (maps.js)
        if($('footer #single-map').length){
          mapSingle();
        }
      },

      _mapMultiple: function() {
        // Create footer map for multiple locations (maps.js)
        if($('footer #multiple-map').length){
          mapMultiple();
        }
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // CF7 on_sent_ok
  formConfirmation = function() {
    // Hide form
    $('form .fieldsets').slideUp(500);

    // Show confirmation message
    setTimeout(function() {
      $('form .conf-msg').fadeIn(500);
    }, 500);
  }

  // CF7 submit on_sent_ok or not
  formScroll = function() {
    // Scroll to header / conf msg
    $('html, body').animate({
        scrollTop: $("form header").offset().top -20
    }, 500);
  }

})(jQuery); // Fully reference jQuery after this point.
